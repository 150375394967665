// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/ui/inputs/input.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/ui/inputs/input.tsx");
  import.meta.hot.lastModified = "1739185044116.9387";
}
// REMIX HMR END

import { Input } from "@nextui-org/react";
const CustomInput = props => {
  return <Input type={props.type} name={props.name} label={props.label} placeholder=' ' labelPlacement='outside' variant='bordered' className={`!shadow-none ${props.className} font-nunito ${props.hidden && "hidden"}`} classNames={{
    inputWrapper: "!shadow-none dark:border-slate-700",
    base: "focus:!border-blue-600",
    label: "text-sm md:text-base font-medium font-sen text-slate-800 dark:text-slate-100"
  }} defaultValue={props.defaultValue} isDisabled={props.isDisabled} isRequired={props.isRequired} value={props.value} onValueChange={props.onValueChange} isReadOnly={props.isReadonly} isInvalid={props.isInvalid} errorMessage={props.errorMessage} endContent={props.endContent} />;
};
_c = CustomInput;
export default CustomInput;
var _c;
$RefreshReg$(_c, "CustomInput");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;